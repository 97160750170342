<template>
  <div v-show="financialData.list.length" class="elv-report-container">
    <div class="left-div">
      <div class="left-div1">
        <table style="width: 100%">
          <thead>
            <tr>
              <th class="elv-table-double-solid-line" :class="{ 'th-from-to-height': dateTimeValues[0]?.fromDate }">
                <div>
                  <div class="elv-table-left-title">
                    {{
                      unit === 'individuals'
                        ? entityStore.entityDetail?.defaultCurrency
                        : $t('common.reportUnit', {
                            unit: $t(`common.${unit}`),
                            currency: entityStore.entityDetail?.defaultCurrency
                          })
                    }}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="left-div2">
        <table class="left-table2">
          <tbody>
            <tr v-for="item in financialStatementShowData" :key="item.fieldId" @click="onClickValue($event, item)">
              <td
                v-if="
                  item?.showType === 'PLACEHOLDER' ||
                  (item?.showType === 'AGG' && (item.parentId === '0' || item.parentId === 0))
                "
                :class="{
                  'elv-table-single-solid-line': item?.lineType === 2 || item?.extra?.showBottomBorder,
                  'custom-made-border': item.extra?.lineBold && item?.showType === 'AGG'
                }"
              >
                <div
                  class="elv-table-level-1-content"
                  :class="{
                    'custom-made-content': item.extra?.lineBold && item?.showType === 'PLACEHOLDER'
                  }"
                >
                  <div>{{ formatLanguageContent(item.name) }}</div>
                </div>
              </td>
              <td
                v-else-if="item?.showType === 'AGG' && (item.parentId !== '0' || item.parentId !== 0)"
                :class="[item?.lineType === 2 ? 'elv-table-single-solid-line' : '']"
              >
                <div class="elv-table-level-2-content">
                  <div style="width: 22px; display: flex; align-items: center" />
                  <div>{{ formatLanguageContent(item.name) }}</div>
                </div>
              </td>
              <td
                v-else-if="item?.showType === 'NORMAL' && item.isGroup"
                :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
              >
                <div class="elv-table-level-3-group-content">
                  <div style="display: flex; align-items: center">
                    <img
                      v-if="isShowArrow && item.extra.whetherExpanded"
                      src="@/assets/img/project/arrow-second-level.png"
                      :style="{ transform: item.expanded ? 'rotate(0deg)' : 'rotate(90deg)' }"
                      @click="onCheckGroupExpanded(item)"
                    />
                  </div>
                  <span>{{ item.name }}</span>
                </div>
              </td>
              <td
                v-else-if="item?.showType === 'NORMAL' && item.isSubGroup"
                :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
              >
                <div class="elv-table-level-3-sub-group-content">
                  <div class="elv-table-level-3-sub-group-content-name">{{ item.name }}</div>
                </div>
              </td>
              <td
                v-else-if="item?.showType === 'NORMAL'"
                :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
              >
                <el-tooltip
                  ref="elvTableTipsRef"
                  :show-arrow="false"
                  effect="light"
                  placement="top"
                  popper-class="elv-table-tips"
                  overlay-classname="elv-table-tips"
                  :show-after="500"
                  :disabled="item?.description && isClickValue ? false : true"
                >
                  <div class="elv-table-level-2-content" :class="{ content_bold: item.extra?.bold }">
                    <div style="width: 22px; display: flex; align-items: center">
                      <img
                        v-if="isShowArrow && item.extra.whetherExpanded"
                        src="@/assets/img/project/arrow-second-level.png"
                        :style="{ transform: item.expanded ? 'rotate(0deg)' : 'rotate(90deg)' }"
                        @click="onCheckGroupExpanded(item)"
                      />
                    </div>
                    <span>{{ formatLanguageContent(item.name) }}</span>
                  </div>
                  <template #content>
                    <div class="elv-table-tips-content">{{ formatLanguageContent(item?.description) }}</div>
                  </template>
                </el-tooltip>
              </td>
              <!-- v-show="item.expanded" -->
              <td
                v-else-if="item?.showType === 'CHILDREN'"
                :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
              >
                <div class="elv-table-level-3-content">
                  <img v-if="item.logo" :src="item.logo" :onerror="useDefaultImage" :alt="item.name" />
                  <img v-else :src="projectDefaultImg" :alt="item.name" />
                  <div class="elv-table-level-3-content">{{ item.name }}</div>
                  <div class="elv-table-level-3-content-symbol">{{ item.showSymbol }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-scrollbar wrap-class="right-div" class="elv-table-right-container" view-class="elv-table-right-container__view">
      <div class="right-div1">
        <table class="right-table1">
          <thead>
            <tr>
              <th
                v-for="item in dateTimeValues"
                :key="item.id"
                class="elv-table-double-solid-line elv-table-title"
                style="width: 90px"
              >
                <div :class="{ 'th-from-to-height': item.fromDate }">
                  <div v-if="item.title" style="padding-bottom: 3px; line-height: 18px">
                    {{ formatDateTitle(item.title) }}
                  </div>
                  <div v-if="item.fromDate" class="elv-table-right-title-date">
                    {{ formatDate('fromDate', item.fromDate) }}
                  </div>
                  <div v-if="item.toDate" class="elv-table-right-title-date" style="padding-bottom: 10px">
                    {{ formatDate('toDate', item.toDate) }}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <el-scrollbar wrap-class="right-div2" class="elv-table-right-content">
        <table class="right-table2">
          <tbody>
            <tr v-for="item in financialStatementShowData" :key="item.fieldId" @click="onClickValue($event, item)">
              <template v-if="item?.showType === 'PLACEHOLDER' && item.showType !== 'AGG'">
                <td
                  v-for="(dateItem, dateIndex) in dateTimeValues"
                  :key="dateIndex"
                  :class="{
                    'elv-table-single-solid-highlight-line': item.extra?.showBottomBorder && dateIndex === 0,
                    'elv-table-single-solid-line': item.extra?.showBottomBorder,
                    'custom-made-content': item.extra?.lineBold
                  }"
                >
                  <div style="width: 90px; height: 40px"></div>
                </td>
              </template>

              <td
                v-for="(valueItem, i) in item.fieldValues"
                v-show="item?.config?.level !== 3 || item?.config?.level === 3"
                :key="i"
                :class="[
                  i === 0
                    ? item?.lineType === 1
                      ? 'elv-table-single-highlight-line'
                      : 'elv-table-single-solid-highlight-line'
                    : item?.lineType === 1
                      ? 'elv-table-single-line'
                      : 'elv-table-single-solid-line',
                  item.showType !== 'AGG' || item?.parentId !== '0' || item?.parentId !== 0
                    ? 'elv-table-value-level-2-3-text'
                    : 'elv-table-value-level-agg-text',
                  props.isClickValue ? '' : 'cursor_none',
                  (item.showType !== 'AGG' && route.name !== 'reports-cash-flow-statement' && item.extra.popover) ||
                  (item.showType === 'AGG' && item.extra.popover) ||
                  (item.showType === 'CHILDREN' && item.extra.popover)
                    ? ''
                    : 'cursor_none',
                  item.extra?.bold ? 'content_bold' : '',
                  item.showType !== 'AGG' && item.extra?.lineBold ? 'custom-made-border' : ''
                ]"
              >
                <div
                  class="elv-table-value-text"
                  :class="{
                    cursor_none:
                      !props.isClickValue ||
                      !(
                        (item.showType !== 'AGG' &&
                          route.name !== 'reports-cash-flow-statement' &&
                          item.extra.popover) ||
                        (item.showType === 'AGG' && item.extra.popover) ||
                        (item.showType === 'CHILDREN' && item.extra.popover)
                      ),
                    'is-error': isErrorCell(valueItem)
                  }"
                  :title="`${`${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`}${valueItem.value}`"
                  :data-index="i"
                >
                  <img v-if="isErrorCell(valueItem)" src="@/assets/img/reports/table-warning-filled.png" alt="error" />
                  {{ valueItem.value === '' ? '' : numDrCrHandle(unit, valueItem.value) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </el-scrollbar>
    </el-scrollbar>
  </div>
  <ComputePopup
    v-if="computePopupVisible"
    :dialog-visible="computePopupVisible"
    dialog-type="ACOUNT"
    balance-type="Dr"
    :unit="unit"
    :date-time="financialValueData.dateTime"
    :event-item="financialValueData.item"
    @closeComputePopup="closeComputePopup"
  />

  <EmptyResult v-if="financialData.list?.length === 0" :img-src="noDataImg" width="125px" height="140px">
    <template #content
      ><p style="font-size: 26px; color: #dde1e6">{{ t('common.noData') }}</p></template
    >
  </EmptyResult>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ComputePopup from './ComputePopup.vue'
import noDataImg from '@/assets/img/reports/noData.png'
import { useEntityStore } from '@/stores/modules/entity'
import { FinancialStatementDataType } from '#/ReportsTypes'
import EmptyResult from '@/components/Base/EmptyResult.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import projectDefaultImg from '@/assets/img/project/project-empty.png'
import { DateTimeType, FinancialDataItemType } from '#/FinancialsTypes'
import { isIOS, numDrCrHandle, formatLanguageContent } from '@/lib/utils'
import { find, filter, isEmpty, clone, throttle, indexOf, remove, has } from 'lodash-es'

const route = useRoute()
const { t, locale } = useI18n()
const reportStore = useReportStore()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const { dateTimeList, financialFieldConfig, financialData } = storeToRefs(reportStore)

const props = defineProps({
  // 数据区间
  dateTime: {
    type: Array<string>,
    default: () => {
      return []
    }
  },
  // 表格距离左侧距离
  paddingLeft: {
    type: String,
    default: () => {
      return '20px'
    }
  },
  // 是否显示箭头
  isShowArrow: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  // value值是否可点击
  isClickValue: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  isDialog: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  // 报表类型 (balance-sheet/income-statement/cash-flow-statement)
  financialType: {
    type: String,
    required: true
  },
  isShowRegeneratedInfo: {
    type: Boolean,
    default: false
  },
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const elvTableTipsRef = ref()
const leftDiv2TouchX = ref()
const leftDiv2TouchY = ref()
const leftDiv2ToTop = ref()

const rightDiv2TouchY = ref()
const rightDiv2ToTop = ref()

const rightDivTouchX = ref()
const isTriggerTouchMove = ref(true)
const computePopupVisible = ref(false)
const financialStatementData: any = ref([])
const financialValueData: any = reactive({
  item: {},
  dateTime: {}
})

const rotate = ref('rotate(0deg)')
const financialStatementTempData = ref()
const dateTimeValues = computed((): DateTimeType[] => {
  let dataList: any = []
  if (props.dateTime.length) {
    dataList = filter(dateTimeList.value, (item: FinancialDataItemType) => {
      return indexOf(props.dateTime, item.id) !== -1
    })
    return dataList
  }
  return dateTimeList.value
})

const titleJustifyContent = computed(() => {
  return navigator.userAgent.indexOf('Firefox') >= 0 ? 'inherit' : 'flex-end'
})

const isErrorCell = computed(() => {
  return (item: any) => {
    if (item?.showType !== 'PLACEHOLDER') {
      const errorData = find(find(reportStore.financialData.errorList, { date: item?.date })?.fieldValues || [], {
        fieldId: item?.fieldId
      })
      return !isEmpty(errorData)
    }
    return false
  }
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const unit = computed(() => {
  return entityDetail.value?.unit ? entityDetail.value?.unit.toLocaleLowerCase() : 'millions'
})

const setLineType = (i: number, lineType: number) => {
  financialStatementTempData.value[i].lineType = lineType
}

const financialStatementShowData = computed(() => {
  const tempFieldList: any = []
  if (financialStatementTempData?.value) {
    financialStatementTempData?.value.forEach((item: FinancialStatementDataType) => {
      if (item?.showType === 'NORMAL' && item.expanded) {
        tempFieldList.push(item.fieldId)
      }
    })
    remove(financialStatementTempData?.value, (item: FinancialStatementDataType) => {
      return tempFieldList.indexOf(item?.parentId) !== -1
    })

    // sugGroup没有爹的 需要移除
    remove(financialStatementTempData?.value, (item: FinancialStatementDataType) => {
      return item?.isSubGroup && !find(financialStatementTempData?.value, { fieldId: item?.parentId })
    })

    for (let i = 0; i < financialStatementTempData.value.length; i += 1) {
      if (financialStatementTempData.value[i]?.showType !== 'PLACEHOLDER') {
        if (
          financialStatementTempData.value[i].showType === 'AGG' &&
          !financialStatementTempData.value[i].extra?.hideBottomLine
        ) {
          setLineType(i, 2)
        } else if (
          financialStatementTempData.value[i + 1]?.showType &&
          financialStatementTempData.value[i + 1].showType === 'AGG' &&
          !financialStatementTempData.value[i + 1].extra?.hideTopLine
        ) {
          setLineType(i, 2)
        } else {
          setLineType(i, 1)
        }
      }
    }
  }
  return financialStatementTempData.value
})
const onCheckGroupExpanded = (item: FinancialStatementDataType) => {
  // 重新拿全量数据
  financialStatementTempData.value = clone(financialStatementData.value)
  rotate.value = item.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  item.expanded = !item.expanded
}

const handleExpandAll = () => {
  financialStatementTempData.value = clone(financialStatementData.value)
  financialStatementTempData.value.forEach((item: FinancialStatementDataType) => {
    if (item.showType === 'NORMAL') {
      item.expanded = true
    }
  })
}

const handleCollapseAll = () => {
  financialStatementTempData.value = clone(financialStatementData.value)
  financialStatementTempData.value.forEach((item: FinancialStatementDataType) => {
    if (item.showType === 'NORMAL') {
      item.expanded = false
    }
  })
}

defineExpose({
  handleExpandAll,
  handleCollapseAll
})

const useDefaultImage = (event: any) => {
  event.target.src = projectDefaultImg
}
const onClickValue = (e: any, item: FinancialStatementDataType) => {
  const target = e.target || e.srcElement
  if (
    (item.showType !== 'AGG' && route.name !== 'reports-cash-flow-statement' && item.extra.popover) ||
    (item.showType === 'AGG' && item?.aggRule?.length) ||
    (item.showType === 'CHILDREN' && item.extra.popover)
  ) {
    if (props.isClickValue && target.className.indexOf('elv-table-value-text') !== -1 && target.tagName === 'DIV') {
      const index = target?.getAttribute('data-index')
      financialValueData.item = item
      financialValueData.dateTime = item.fieldValues[index]
      // if (item.showType === 'CHILDREN') {
      //   financialValueData.item.showType = 'NORMAL'
      // }
      //  暂时注释L1,L2的弹窗
      if (item.showType === 'AGG') {
        computePopupVisible.value = true
      }
    }
  }
}
const formatDate = (type: string, date: string) => {
  if (locale.value === 'zh' && type === 'toDate') return `- ${date}`
  if (locale.value === 'zh') return date
  if (type === 'fromDate') return dayjs(date).format('D MMM YYYY')
  if (type === 'toDate') return dayjs(date).format('-D MMM YYYY')
  return date
}

const formatDateTitle = (date: string) => {
  if (props.financialType !== 'balance-sheet' && props.financialType !== 'restrictions')
    return formatLanguageContent(date)
  if (locale.value === 'zh') return date
  return dayjs(date).format('D MMMM\n YYYY')
}
const closeComputePopup = () => {
  computePopupVisible.value = false
  financialValueData.item = {}
  financialValueData.dateTime = {}
}

const rightScroll = () => {
  const top = document.getElementsByClassName('right-div2')[0].scrollTop
  const left = document.getElementsByClassName('left-div1')[0].scrollLeft
  document.getElementsByClassName('left-div2')[0].scrollTop = top
  document.getElementsByClassName('right-div1')[0].scrollLeft = left
}

const rightContentScroll = () => {
  const top = document.getElementsByClassName('right-div2')[0].scrollTop
  const left = document.getElementsByClassName('left-div1')[0].scrollLeft
  document.getElementsByClassName('left-div2')[0].scrollTop = top
  document.getElementsByClassName('right-div1')[0].scrollLeft = left
}

const leftScroll = () => {
  const top = document.getElementsByClassName('right-div1')[0].scrollTop
  const leftDivTop = document.getElementsByClassName('left-div2')[0].scrollTop
  document.getElementsByClassName('left-div1')[0].scrollTop = top
  document.getElementsByClassName('right-div2')[0].scrollTop = leftDivTop
}
const setFinancialStatementData = async (type: string = 'all') => {
  financialStatementData.value = await reportStore.getFinancialStatementData(type === 'all' ? [] : props.dateTime)
  financialStatementTempData.value = clone(financialStatementData.value)
  // 首页默认全部二级折叠
  financialStatementTempData.value.forEach((item: FinancialStatementDataType) => {
    if (item.showType === 'NORMAL') {
      item.expanded = true
    }
  })
}

const bindTouchEvents = () => {
  const leftDiv2Element = document.getElementsByClassName('left-div2')[0]
  const rightDiv2Element = document.getElementsByClassName('right-div2')[0]
  const rightDivElement = document.getElementsByClassName('right-div')[0]

  if (leftDiv2Element) {
    leftDiv2Element.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        leftDiv2TouchX.value = event.changedTouches[0].clientX
        leftDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
    leftDiv2Element.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (isTriggerTouchMove.value) {
          if (event.cancelable) event.preventDefault()

          if (elvTableTipsRef.value.length > 0) {
            elvTableTipsRef.value.forEach((item: any) => {
              item.onClose()
            })
          }
          // 表格拖动
          const offsetX = event.changedTouches[0].clientX - leftDiv2TouchX.value
          const offsetY = event.changedTouches[0].clientY - leftDiv2TouchY.value

          leftDiv2Element.scrollLeft -= offsetX
          leftDiv2Element.scrollTop -= offsetY
          rightDiv2Element.scrollTop -= offsetY

          leftDiv2TouchX.value = event.changedTouches[0].clientX
          leftDiv2TouchY.value = event.changedTouches[0].clientY
          const toTop = leftDiv2Element.scrollTop < 5
          if (leftDiv2ToTop.value !== toTop) {
            leftDiv2ToTop.value = toTop
          }
        }
      }, 30),
      true
    )
    leftDiv2Element.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        leftDiv2TouchX.value = event.changedTouches[0].clientX
        leftDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
  }

  if (rightDivElement) {
    rightDivElement.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        rightDivTouchX.value = event.changedTouches[0].clientX
        isTriggerTouchMove.value = false
      },
      true
    )
    rightDivElement.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (event.cancelable) event.preventDefault()
        // 表格拖动
        const offsetX = event.changedTouches[0].clientX - rightDivTouchX.value

        rightDivElement.scrollLeft -= offsetX
        rightDivTouchX.value = event.changedTouches[0].clientX
      }, 30),
      true
    )
    rightDivElement.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        rightDivTouchX.value = event.changedTouches[0].clientX
        isTriggerTouchMove.value = true
      },
      true
    )
  }

  if (rightDiv2Element) {
    rightDiv2Element.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        rightDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
    rightDiv2Element.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (event.cancelable) event.preventDefault()
        if (elvTableTipsRef.value.length > 0) {
          elvTableTipsRef.value.forEach((item: any) => {
            item.onClose()
          })
        }
        // 表格拖动
        const offsetY = event.changedTouches[0].clientY - rightDiv2TouchY.value

        rightDiv2Element.scrollTop -= offsetY
        leftDiv2Element.scrollTop -= offsetY

        rightDiv2TouchY.value = event.changedTouches[0].clientY
        const toTop = rightDiv2Element.scrollTop < 5
        if (rightDiv2ToTop.value !== toTop) {
          rightDiv2ToTop.value = toTop
        }
      }, 30),
      true
    )
    rightDiv2Element.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        rightDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
  }
}

const financialsDataInit = async () => {
  try {
    const params: any = {
      period: 'MONTH',
      dateRange: []
    }
    const startDate = dayjs().subtract(6, 'month').date(1).startOf('day')
    const endDate = dayjs().endOf('month').endOf('day')
    params.dateRange = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
    const periodDateOperatingRecord = find(reportStore.reportPeriodDateOperateList, { entityId: entityId.value })
    if (!isEmpty(periodDateOperatingRecord) && !isEmpty(periodDateOperatingRecord.other)) {
      params.period =
        periodDateOperatingRecord.other.period === 'date'
          ? 'DAY'
          : periodDateOperatingRecord.other.period.toLocaleUpperCase()
      params.dateRange = periodDateOperatingRecord.other.dateValue
    }
    const requestParams = has(props.params, 'group')
      ? { ...params, group: props.params.group, subGroup: props.params.subGroup }
      : params
    await reportStore.financialsDataInit(
      entityId.value,
      props.financialType,
      props.params?.period ? props.params : requestParams
    )
  } catch (error) {
    console.log(error)
  }
}
emitter.on('financialsDataInit', financialsDataInit)

watch(
  [() => route.name, () => props.financialType, () => entityDetail.value?.entityId],
  async () => {
    if (!reportStore.auxiliaryTypeList.length) {
      await reportStore.getAllAuxiliaryTypeList(entityId.value)
    }
    if (route.name === `reports-${props.financialType}` && !props.isDialog && entityDetail.value?.entityId !== '') {
      await financialsDataInit()
    }
  },
  { immediate: true }
)
watch(
  [() => [...props.dateTime], () => financialFieldConfig.value, () => financialData.value.list],
  async () => {
    if (financialFieldConfig.value.length > 0 && financialData.value.list.length > 0) {
      setFinancialStatementData('props')
    }
  },
  { immediate: true }
)
onMounted(async () => {
  nextTick(() => {
    document.getElementsByClassName('right-div')[0]?.addEventListener('scroll', throttle(rightScroll))
    document.getElementsByClassName('right-div2')[0]?.addEventListener('scroll', throttle(rightContentScroll))
    document.getElementsByClassName('left-div2')[0]?.addEventListener('scroll', throttle(leftScroll))
    if (isIOS()) {
      bindTouchEvents()
    }
  })
})
onBeforeUnmount(() => {
  document.getElementsByClassName('right-div')[0]?.removeEventListener('scroll', rightScroll)
  document.getElementsByClassName('right-div2')[0]?.removeEventListener('scroll', rightContentScroll)
  document.getElementsByClassName('left-div2')[0]?.removeEventListener('scroll', leftScroll)
  // if (isIOS()) {
  //   document.body.removeEventListener('touchstart', touchstartFunction)
  //   document.body.removeEventListener('touchmove', touchmoveFunction)
  // }

  emitter.off('financialsDataInit', financialsDataInit)
})
</script>
<style lang="scss" scoped>
:deep(.elv-table-tips-content) {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-report-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  padding-left: v-bind('props.paddingLeft');
  position: relative;

  .left-div {
    height: 100%;
    position: relative;
    flex: 0 0;
    flex-basis: auto;
    @media screen and (max-width: 1160px) {
      width: auto;
    }
    @media screen and (max-width: 820px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    @media screen and (max-width: 390px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }

  .left-div1 {
    display: flex;

    &::after {
      content: '';
      height: 100%;
      width: 10px;
      display: block;
    }
  }

  .left-div2 {
    height: calc(100% - 95px);
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    width: fit-content;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  :deep(.elv-table-right-content.el-scrollbar) {
    height: calc(100% - 95px);
    width: fit-content;
    overflow: unset;
  }

  :deep(.right-div2) {
    height: 100%;
    padding-right: 16px;
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }

  :deep(.right-div) {
    height: 100%;
    flex: 1;
    min-width: 0;
    position: relative;
    @media screen and (max-width: 820px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }

    @media screen and (max-width: 768px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }
    @media screen and (max-width: 390px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }
  }
}

table {
  //   width: 100%;
  text-align: center;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

table td {
  word-break: break-all;
  word-wrap: break-word;
  // box-sizing: content-box;
}

.left-table2 {
  border-right: 10px solid transparent;
  @media screen and (max-width: 820px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 390px) {
    margin-bottom: 40px;
  }

  tr {
    &:last-child {
      td {
        border-right: 0px;
      }
    }

    &::after {
      height: 100%;
      width: 0px;
      content: '';
      display: block;
    }
  }

  td {
    box-sizing: border-box;
  }

  td .elv-table-level-2-content span,
  .elv-table-level-1-content > div {
    @media screen and (max-width: 820px) {
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    @media screen and (max-width: 390px) {
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}

.right-table1 {
  th {
    > div {
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: v-bind('titleJustifyContent');
    }
  }

  tr:first-child {
    th {
      &:nth-child(1) {
        border-right: 10px solid transparent;
        border-bottom-color: #1343bf !important;
      }
    }
  }
}

.right-table2 {
  @media screen and (max-width: 820px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 390px) {
    margin-bottom: 40px;
  }

  tr {
    &:last-child {
      td {
        &:nth-child(1) {
          border-bottom-color: #1343bf !important;
        }
      }
    }
  }

  td {
    &:nth-child(1) {
      border-right: 10px solid transparent;
    }

    &.custom-made-border {
      border-bottom-width: 2px !important;
    }

    &.content_bold {
      font-weight: 800;
    }

    &.custom-made-content {
      height: 73px;
      border-bottom: 2px solid #000;

      &:first-of-type {
        border-bottom-color: #1343bf;
      }
    }
  }
}

th {
  height: 50px;
  vertical-align: bottom;
  padding-bottom: 10px;
}

.th-from-to-height {
  height: 70px;
}

tr {
  th {
    padding: 0px;

    &:nth-child(1) {
      color: #1343bf !important;

      .elv-table-right-title-date {
        color: #1343bf !important;
      }
    }
  }

  td {
    padding: 0px;
    height: fit-content;

    &:nth-child(1) {
      border-right: 0px;
      // border-right: 10px solid transparent;
    }
  }

  &:last-child {
    td {
      border-bottom: 2px solid #1e2024;
    }
  }
}

.elv-table-right-container {
  height: 100%;

  :deep(.elv-table-right-container__view) {
    height: 100%;
  }

  :deep(.el-scrollbar__bar.is-horizontal) {
    transform: translateY(-12px);
  }
}

.elv-table-left-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  color: #1e2024;
  font-size: 12px;
  text-align: left;
  padding-bottom: 8px;
}

.elv-table-right-title-date {
  font-family: 'Plus Jakarta Sans';
  color: #000;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  zoom: 0.83;
  -moz-transform: scale(0.83);
  -moz-transform-origin: top right;
}

.elv-table-level-3-group-content {
  display: flex;
  align-items: center;
  height: 40px;

  img {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    // margin-left: 24px;
    cursor: pointer;
  }

  div {
    margin-left: 28px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #0e0f11;
  }

  span {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #0e0f11;
  }
}

.elv-table-level-3-sub-group-content {
  display: flex;
  align-items: center;
  height: 40px;

  div {
    margin-left: 68px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #0e0f11;
  }
}

.elv-table-level-3-content {
  display: flex;
  align-items: center;
  height: 40px;

  img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: 24px;
  }

  div {
    margin-left: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #0e0f11;
  }
}

.elv-table-level-3-content-symbol {
  margin-left: 10px;
  font-family: 'Plus Jakarta Sans' !important;
  font-weight: 400;
  font-size: 14px !important;
  text-transform: capitalize;
  color: #aaafb6 !important;
}

.elv-table-level-1-content {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  text-align: left;

  &.custom-made-content {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    height: 75px;
    padding: 24px 40px 16px 0px;
    border-bottom: 2px solid #1e2024;
  }
}

.elv-table-level-2-content {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  color: #0e0f11;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 20px;

  span {
    white-space: nowrap;
  }

  &.content_bold {
    font-weight: 800;
  }

  img {
    height: 16px;
    width: 16px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    display: block;
  }
}

.elv-table-title {
  white-space: pre;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 12px;
  // line-height: 21px;
  text-align: right;
}

.elv-table-double-solid-line {
  border-bottom: 2px solid #1e2024;
}

.elv-table-single-solid-line {
  border-bottom: 1px solid #1e2024;

  &.custom-made-border {
    border-bottom: 2px solid #1e2024;
  }
}

.elv-table-single-line {
  border-bottom: 1px solid #edf0f3;
}

.elv-table-double-solid-highlight-line {
  border-bottom: 2px solid #1343bf;
}

.elv-table-single-solid-highlight-line {
  border-bottom: 1px solid #1343bf;
  color: #1343bf !important;
}

.elv-table-single-highlight-line {
  border-bottom: 1px solid #cedaf8;
  color: #1343bf !important;
}

.elv-table-value-level-agg-text {
  width: 90px;
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-align: right;
}

.elv-table-value-level-2-3-text {
  width: 90px;
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  text-transform: capitalize;
  color: #000000;
  text-align: right;
}

.elv-table-value-text {
  min-width: 50px !important;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;

  img {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  &.is-error {
    color: #eb3a30 !important;
  }
}

.cursor_none {
  cursor: default;
}
</style>
